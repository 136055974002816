/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('../bootstrap');
require('../plugins');

import Vue from 'vue';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

Vue.component('example-component', require('./components/ExampleComponent.vue').default);

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

const app = new Vue({
    el: '#app',
});
//text area CKEditor work
let textArea = document.querySelectorAll( "textarea" );
ClassicEditor.defaultConfig = {
    toolbar: {
        items: [
            'heading',
            '|',
            'bold',
            'italic',
            'link',
            '|',
            'bulletedList',
            'numberedList',
            'blockQuote',
            '|',
            'undo',
            'redo'
        ],

    },
};
function MinHeightPlugin(editor) {
    this.editor = editor;
}

MinHeightPlugin.prototype.init = function() {
    this.editor.ui.view.editable.extendTemplate({
        attributes: {
            style: {
                minHeight: '15rem'
            }
        }
    });
};
let lang = document.documentElement.lang;
ClassicEditor.builtinPlugins.push(MinHeightPlugin);
for (var i = 0; i < textArea.length; ++i) {
    ClassicEditor
        .create(textArea[i],{
            language: {
                ui:lang,
                content:lang
            }
        })
        .then( editor => {

        } )
        .catch( error => {
            console.error( error.stack );
        } );
}
//end CKEditor work
